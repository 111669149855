import React from "react"
import { Link } from "gatsby"

import Logo from 'images/logo.svg'
import styles from './contact-submitted.module.scss'

const ContactSubmitted = () => (
  <>
    <div className={styles.blockLogo}>
      <Link to="/">
        <img src={Logo} alt="Binary Stride Logo" className={styles.imgLogo} />
      </Link>
    </div>
    <div className={styles.textHeader}>Thanks! Your message is on the way.</div>
    <div className={styles.textDesc}>We’ll get back to you shortly.</div>
  </>
)

export default ContactSubmitted
