import React from "react"

import ContactSubmitted from 'components/home/contact/contact-submitted/contact-submitted'
import SEO from 'components/seo'
import { Helmet } from "react-helmet"

const ContactSubmittedPage = () => (
  <>
    <SEO title="Thank you" />
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <ContactSubmitted />
  </>
)

export default ContactSubmittedPage
